// these are ripped from https://github.com/Adheretech/Adheretech-site-v2/blob/master/project/data/pressItems.js,
// which ripped from the old https://github.com/Adheretech/adheretech.com/blob/master/themes/adheretech/data/pressitems.json
export default [
  {
    title: `AdhereTech and Massive Bio, Two of NYC Digital Health 100 most Promising Start-Ups, Announce AI-Enabled, Patient-Centric Oncology Solutions Partnership`,
    link: `https://www.businesswire.com/news/home/20220607005289/en/AdhereTech-and-Massive-Bio-Two-of-NYC-Digital-Health-100-most-Promising-Start-Ups-Announce-AI-Enabled-Patient-Centric-Oncology-Solutions-Partnership`,
    publication: `Businesswire`,
    pubmonth: 'June',
    pubyear: 2022,
    rank: 0,
  },
  {
    title: `Connecting Dots with Data: New Tools Pharmacists Need to Help Their Patients Succeed`,
    link: 'https://www.pharmacytimes.com/view/connecting-dots-with-data-new-tools-pharmacists-need-to-help-their-patients-succeed',
    publication: `Pharmacy Times`,
    pubmonth: 'February',
    pubyear: 2022,
    rank: 0,
  },
  {
    title: `AdhereTech named to the NYC Digital Health 100 by NYC Health Business Leaders`,
    link: 'https://www.nychbl.com/dh100/',
    publication: `New York City Health Business Leaders`,
    pubmonth: 'February',
    pubyear: 2022,
    rank: 0,
  },
  {
    title: `AdhereTech Updated the Pill Bottle, Now They’ve Launched the Aidia System`,
    link: 'https://www.youtube.com/watch?v=-VTsZ_EKKvU',
    publication: `StartUp Health TV`,
    pubmonth: 'December',
    pubyear: 2021,
    rank: 0,
  },
  {
    title: `Supporting Patients on their Healthcare Journey with Lyndi Hirsch`,
    link: 'https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xNTY0NDkwLnJzcw/episode/QnV6enNwcm91dC05NTY1MDc5?sa=X&ved=0CAUQkfYCahcKEwjY_Y2plKD1AhUAAAAAHQAAAAAQAQ&hl=en',
    publication: `The Future of Healthcare`,
    pubmonth: 'November',
    pubyear: 2021,
    rank: 0,
  },
  {
    title:
      'Improving Medication Compliance with Connected Pill Bottles and More: Interview with Chris O’Brien, CEO at AdhereTech',
    link: 'https://www.medgadget.com/2021/10/improving-medication-compliance-with-connected-pill-bottles-and-beyond-interview-with-chris-obrien-ceo-at-adheretech.html',
    publication: 'MedGadget',
    pubmonth: 'October',
    pubyear: 2021,
    rank: 0,
  },
  {
    title: `Take Your Medicine: Can a 'Smart' Pill Bottle Help Combat HIV?`,
    link: 'https://news.weill.cornell.edu/news/2021/09/take-your-medicine-can-a-%E2%80%98smart%E2%80%99-pill-bottle-help-combat-hiv',
    publication: `Weill Cornell Medicine Newsroom`,
    pubmonth: 'September',
    pubyear: 2021,
    rank: 0,
  },
  {
    title: 'AdhereTech Unveils Aidia System™ to Keep Patients Connected to Care',
    link: 'https://www.businesswire.com/news/home/20210915005368/en/AdhereTech-Unveils-Aidia-SystemTM-to-Keep-Patients-Connected-to-Care',
    publication: 'Businesswire',
    pubmonth: 'September',
    pubyear: 2021,
    rank: 0,
  },
  {
    title:
      'AdhereTech Announces Partnership with AARDEX Group to Boost Medication Adherence with Smart Pill Bottle, Software and Data Analytics',
    link: 'https://www.businesswire.com/news/home/20210610005183/en/AdhereTech-Announces-Partnership-with-AARDEX-Group-to-Boost-Medication-Adherence-with-Smart-Pill-Bottle-Software-and-Data-Analytics',
    publication: 'Businesswire',
    pubmonth: 'June',
    pubyear: 2021,
    rank: 0,
  },
  {
    title: 'Argentum Makes Growth Equity Investment in AdhereTech',
    link: 'https://www.argentumgroup.com/argentum-makes-growth-equity-investment-in-adheretech/',
    publication: 'Argentum',
    pubmonth: 'November',
    pubyear: 2018,
    rank: 1,
  },
  {
    title: 'Smart Pill Bottle Creator AdhereTech Secures Strategic Investment From Argentum',
    link: 'https://medcitynews.com/2018/11/smart-pill-bottle-adheretech-argentum/',
    publication: 'MedCity News',
    pubmonth: 'November',
    pubyear: 2018,
    rank: 1,
  },
  {
    title: 'AdhereTech Raises Growth Equity Round to Scale, Improve Smart Pill Bottle-Based Adherence Platform',
    link: 'https://www.mobihealthnews.com/content/adheretech-raises-growth-equity-round-scale-improve-smart-pill-bottle-based-adherence',
    publication: 'MobiHealthNews',
    pubmonth: 'November',
    pubyear: 2018,
    rank: 2,
  },
  {
    title: 'AdhereTech Raises Growth Equity from Argentum',
    link: 'https://www.prweb.com/releases/adheretech_raises_growth_equity_from_argentum/prweb15881867.htm',
    publication: 'PR Web',
    pubmonth: 'November',
    pubyear: 2018,
    rank: 3,
  },
  {
    title: 'AdhereTech: Assisting Patients to Take Medication on Time',
    link: 'http://magazines.insightssuccess.com/The-10-Most-Innovative-Healthcare-Solution-Providers-2018/#page=26',
    publication: 'Insights Success',
    pubmonth: 'August',
    pubyear: 2018,
    rank: 0,
  },
  {
    title: 'Improving Medication Compliance with Smart Pill Bottles: Interview with AdhereTech CEO Josh Stein',
    link: 'https://www.medgadget.com/2018/07/improving-medication-compliance-with-smart-pill-bottles-interview-with-adheretech-ceo-josh-stein.html',
    publication: 'Medgadget',
    pubmonth: 'July',
    pubyear: 2018,
    rank: 0,
  },
  {
    title:
      "Avella's Use of AdhereTech Improves Adherence and Generates One to Two Additional Fills Per Patient Per Year, Across Multiple Specialty Medications and Diseases",
    link: 'https://www.avella.com/news/avellas-use-of-adheretech-improves-adherence-and-generates-one-to-two-additional-fills-per-patient-per-year-across-multiple-specialty-medications-and-diseases',
    publication: 'Avella Specialty Pharmacy',
    pubmonth: 'July',
    pubyear: 2018,
    rank: 1,
  },
  {
    title: 'AdhereTech Smart Bottle on Display at Smithsonian Design Museum',
    link: 'https://collection.cooperhewitt.org/objects/1158817843/',
    publication: 'Cooper Hewitt Smithsonian Design Museum',
    pubmonth: 'June',
    pubyear: 2018,
    rank: 1,
  },
  {
    title: 'How the Internet of Things Will Change Everything',
    link: 'https://video.wired.com/watch/internet-of-things-tech-today-and-tomorrow-episode-1',
    publication: 'WIRED',
    pubmonth: 'March',
    pubyear: 2018,
    rank: 1,
  },
  {
    title: 'A Decade In, Still Smarter Than Your Average Innovator',
    link: 'http://www.innovateli.com/decade-still-smarter-average-innovator/',
    publication: 'Innovate LI',
    pubmonth: 'February',
    pubyear: 2018,
    rank: 1,
  },
  {
    title: 'AdhereTech’s Smart System Improves Medication Compliance',
    link: 'https://www.medgadget.com/2018/02/adheretechs-smart-system-improves-medication-compliance.html',
    publication: 'Medgadget',
    pubmonth: 'February',
    pubyear: 2018,
    rank: 0,
  },
  {
    title: 'IoT in Marketing: 10 Companies on the Front Lines',
    link: 'https://www.disruptordaily.com/future-marketing-iots-10-companies-front-lines-2/',
    publication: 'Disruptor Daily',
    pubmonth: 'January',
    pubyear: 2018,
    rank: 1,
  },
  {
    title: 'Innovators 2017: AdhereTech',
    link: 'https://www.pm360online.com/innovators-2017-companies/?GTTabs=0',
    publication: 'PM360',
    pubmonth: 'December',
    pubyear: 2017,
    rank: 0,
  },
  {
    title: 'Smart Pill Bottles Among New Technologies Emerging to Boost Patient Outcomes',
    link: 'https://www.avella.com/news/smart-pill-bottles-among-new-technologies-emerging-to-boost-patient-outcomes',
    publication: 'Benefits Canada',
    pubmonth: 'November',
    pubyear: 2017,
    rank: 1,
  },
  {
    title: 'Can Digital Pills and Drug Delivery Systems Get People to Take Their Meds?',
    link: 'https://www.smithsonianmag.com/innovation/can-digital-pills-drug-delivery-systems-get-people-take-their-meds-180967287/?utm_content=buffer36751&utm_medium=social&utm_source=twitter.com&utm_campaign=buffer',
    publication: 'Smithsonian Magazine',
    pubmonth: 'November',
    pubyear: 2017,
    rank: 0,
  },
  {
    title: 'Three Industries Undergoing IoT Innovation',
    link: 'https://www.rdmag.com/article/2017/09/three-industries-undergoing-iot-innovation',
    publication: 'R&D Magazine',
    pubmonth: 'September',
    pubyear: 2017,
    rank: 0,
  },
  {
    title: 'Glimpse into the Future of Retail and Healthcare',
    link: 'https://www.pharmacytimes.com/conferences/nacds-2017/nacds-reveals-glimpse-into-future-of-retail-and-healthcare-at-2017-total-store-expo',
    publication: 'Pharmacy Times',
    pubmonth: 'August',
    pubyear: 2017,
    rank: 1,
  },
  {
    title: 'AdhereTech & Avella Speciality Pharmacy',
    link: 'https://www.avella.com/adheretech',
    publication: 'Avella Speciality Pharmacy',
    pubmonth: 'August',
    pubyear: 2017,
    rank: 0,
  },
  {
    title: 'New Technology Could Change Behavior Between Patients and Medication',
    link: 'http://www.wsfa.com/story/35715476/new-technology-could-change-behavior-between-patients-and-medication',
    publication: 'NBC News (WFSA)',
    pubmonth: 'June',
    pubyear: 2017,
    rank: 0,
  },
  {
    title: 'Smart Pill Bottle Can Help Patients Take Their Meds On Time',
    link: 'http://www.kcbd.com/story/35710857/smart-pill-bottle-can-help-patients-take-their-meds-on-time',
    publication: 'NBC News (KCBD)',
    pubmonth: 'June',
    pubyear: 2017,
    rank: 1,
  },
  {
    title: 'Smart Pill Bottles Help Health Care Providers',
    link: 'http://www.nwahomepage.com/news/smart-pill-bottles-help-health-care-providers/745994113',
    publication: 'NBC News (KWNA)',
    pubmonth: 'June',
    pubyear: 2017,
    rank: 3,
  },
  {
    title: 'How Smart Tech is Injecting New Life into Healthcare',
    link: 'https://www.marketingweek.com/2017/06/12/smart-tech-new-life-healthcare/',
    publication: 'Marketing Week',
    pubmonth: 'June',
    pubyear: 2017,
    rank: 4,
  },
  {
    title: '10 Healthcare Companies Among Top 100 Most Disruptive Brands 2017',
    link: 'http://www.beckershospitalreview.com/hospital-management-administration/11-healthcare-companies-among-top-100-most-disruptive-brands-2017.html',
    publication: "Becker's Hospital Review",
    pubmonth: 'June',
    pubyear: 2017,
    rank: 5,
  },
  {
    title: 'Marketing Week’s 100 Disruptive Brands 2017: AdhereTech',
    link: 'https://www.marketingweek.com/disruptive-brands/adheretech/?cn=bWVudGlvbg%3D%3D',
    publication: 'Marketing Week',
    pubmonth: 'June',
    pubyear: 2017,
    rank: 6,
  },
  {
    title: 'A Startup Helping Patients Stay on Top of Their Meds',
    link: 'https://www.cnbc.com/2017/06/05/a-start-up-helping-patients-stay-on-top-of-their-meds.html',
    publication: 'CNBC',
    pubmonth: 'June',
    pubyear: 2017,
    rank: 9,
  },
  {
    title: '10 Ways The Internet of Medical Things Is Revolutionizing Senior Care',
    link: 'https://www.forbes.com/sites/reenitadas/2017/05/22/10-ways-internet-of-medical-things-is-revolutionizing-senior-care/#685de6e25c8f',
    publication: 'Forbes',
    pubmonth: 'May',
    pubyear: 2017,
    rank: 0,
  },
  {
    title: "AdhereTech Wins A' Design Award",
    link: 'https://competition.adesignaward.com/design.php?ID=48818',
    publication: "A' Design Award and Competition",
    pubmonth: 'May',
    pubyear: 2017,
    rank: 2,
  },
  {
    title: 'AdhereTech on IPS Radio',
    link: 'https://itunes.apple.com/us/podcast/ips-radio/id1195705259?mt=2&i=1000385539692&ign-mpt=uo%3D4',
    publication: 'Apple iTunes',
    pubmonth: 'May',
    pubyear: 2017,
    rank: 3,
  },
  {
    title: 'The Next Challenge for AI: Fighting Blindness',
    link: 'https://www.rtinsights.com/using-artificial-intelligence-for-diabetic-retinopathy-screening/',
    publication: 'RT Insights',
    pubmonth: 'May',
    pubyear: 2017,
    rank: 4,
  },
  {
    title: 'Smart Medicine: Designing IoT Products in Healthcare',
    link: 'https://www.rtinsights.com/smart-medicine-iot-products-design-in-healthcare/',
    publication: 'RT Insights',
    pubmonth: 'April',
    pubyear: 2017,
    rank: 0,
  },
  {
    title: 'How Home Care Can Help Empower Cancer Patients',
    link: 'https://www.futurehealthindex.com/2017/03/16/home-care-can-help-empower-cancer-patients/',
    publication: 'Philips Future Health Index',
    pubmonth: 'March',
    pubyear: 2017,
    rank: 0,
  },
  {
    title: 'The Internet of Medical Things',
    link: 'http://www.whartonhealthcare.org/the_internet_of_medical_things',
    publication: 'Wharton Healthcare',
    pubmonth: 'March',
    pubyear: 2017,
    rank: 1,
  },
  {
    title: 'Specialty Pharmacies Transforming Cancer Care',
    link: 'http://www.ajmc.com/journals/evidence-based-oncology/2017/patient-centered-oncology-care-2016/Specialty-Pharmacies-Transforming-Cancer-Care',
    publication: 'American Journal of Managed Care',
    pubmonth: 'February',
    pubyear: 2017,
    rank: 1,
  },
  {
    title: 'AdhereTech Recognized by the Prestigious International GOOD DESIGN Award',
    link: 'https://finance.yahoo.com/news/adheretech-ips-recognized-prestigious-international-150200666.html',
    publication: 'Yahoo Finance',
    pubmonth: 'February',
    pubyear: 2017,
    rank: 2,
  },
  {
    title: "International Design Honor For AdhereTech's Smart Bottle",
    link: 'http://www.innovateli.com/international-design-honor-ips-smart-bottle/',
    publication: 'Innovate Long Island',
    pubmonth: 'February',
    pubyear: 2017,
    rank: 3,
  },
  {
    title: 'Founders Share How They Assembled Their Teams',
    link: 'http://www.longevitynetwork.org/exclusive/making-dream-work-startup-founders-share-assembled-teams/',
    publication: 'The Longevity Network',
    pubmonth: 'February',
    pubyear: 2017,
    rank: 5,
  },
  {
    title: 'AdhereTech’s Smart Wireless Pill Bottle',
    link: 'http://www.innovationessence.com/adheretechs-wireless-bottle/',
    publication: 'Innovation Essence',
    pubmonth: 'January',
    pubyear: 2017,
    rank: 0,
  },
  {
    title: '13 NYC IoT Companies Connecting Everything to Everything Else',
    link: 'http://www.builtinnyc.com/2017/01/01/iot-companies-making-awesome-products-nyc',
    publication: 'Built in NYC',
    pubmonth: 'January',
    pubyear: 2017,
    rank: 1,
  },
  {
    title: '5 Breakthrough Healthcare Startups',
    link: 'http://insurancethoughtleadership.com/5-breakthrough-healthcare-startups/',
    publication: 'Inurance Thought Leadership',
    pubmonth: 'December',
    pubyear: 2016,
    rank: 0,
  },
  {
    title: 'Mobile Pharmacy Apps Revolutionize Medication Adherence',
    link: 'https://insights.samsung.com/2016/12/12/mobile-pharmacy-apps-revolutionize-medication-adherence/',
    publication: 'Samsung',
    pubmonth: 'December',
    pubyear: 2016,
    rank: 1,
  },
  {
    title: 'The IoT User Interface Designs: Thinking Beyond the Screen',
    link: 'http://internetofthingsagenda.techtarget.com/feature/The-IoT-user-interface-designs-Thinking-beyond-the-screen',
    publication: 'Internet of Things Agenda',
    pubmonth: 'December',
    pubyear: 2016,
    rank: 2,
  },
  {
    title: 'AdhereTech Wins the 2016 Cleveland Clinic Medical Innovation Summit',
    link: 'https://www.youtube.com/watch?v=KwZ-ONA44KA',
    publication: 'Cleveland Clinic',
    pubmonth: 'November',
    pubyear: 2016,
    rank: 0,
  },
  {
    title: 'AdhereTech and the Smart Pill Bottle',
    link: 'https://rctom.hbs.org/submission/adheretech-and-the-smart-pill-bottle/',
    publication: 'Harvard Business School',
    pubmonth: 'November',
    pubyear: 2016,
    rank: 1,
  },
  {
    title: 'AdhereTech Wins the MedCity Patient Engagement Competition',
    link: 'http://medcitynews.com/2016/10/medcity-announces-winners-patient-engagement-startup-contest/',
    publication: 'MedCity News',
    pubmonth: 'October',
    pubyear: 2016,
    rank: 0,
  },
  {
    title: 'Insights from the Connected Health Symposium in Boston',
    link: 'https://blogs.perficient.com/healthcare/blog/2016/10/24/insights-from-the-connected-health-symposium-in-boston/',
    publication: 'Perficient',
    pubmonth: 'October',
    pubyear: 2016,
    rank: 1,
  },
  {
    title: 'Improve Adherence with Smart Pill Bottles for Patients Taking Certain Oral Oncolytics Medications',
    link: 'https://blog.avella.com/improve-adherence-with-smart-pill-bottles-for-oncology-patients',
    publication: 'Avella Specialty Pharmacy',
    pubmonth: 'October',
    pubyear: 2016,
    rank: 3,
  },
  {
    title: 'Nonadherent Patients: Your Pill Bottle Is Calling!',
    link: 'http://www.pharmacypracticenews.com/Technology/Article/09-16/Nonadherent-Patients-Your-Pill-Bottle-Is-Calling-/37859/ses=ogst',
    publication: 'Pharmacy Practice News',
    pubmonth: 'September',
    pubyear: 2016,
    rank: 0,
  },
  {
    title: '10 Cool Ways Companies Are Innovating In Health-Care IoT',
    link: 'http://www.crn.com/slide-shows/internet-of-things/300081848/10-cool-ways-companies-are-innovating-in-health-care-iot.htm/pgno/0/1',
    publication: 'CRN.com',
    pubmonth: 'August',
    pubyear: 2016,
    rank: 0,
  },
  {
    title: '3 Key Ways Digital Health is Changing Medication Adherence in Clinical Trials',
    link: 'http://www.healthcareitnews.com/sponsored-content/3-key-ways-digital-health-changing-medication-adherence-clinical-trials-0',
    publication: 'Validic, via Healthcare IT News',
    pubmonth: 'August',
    pubyear: 2016,
    rank: 1,
  },
  {
    title: 'Retail Innovation',
    link: 'https://gallery.mailchimp.com/6965c65630ff4f908e5d706da/files/Retail_Innovation_Report_Q2_2016_v1.8.pdf',
    publication: 'Connecting Retail to Innovation',
    pubmonth: 'July',
    pubyear: 2016,
    rank: 1,
  },
  {
    title: "AdhereTech at Austria's National Science Museum",
    link: 'https://twitter.com/AdhereTech/status/748912604605710336',
    publication: 'National Science Museum',
    pubmonth: 'July',
    pubyear: 2016,
    rank: 2,
  },
  {
    title: 'The Internet of Things (IoT) in Healthcare: Market Analysis and Forecasts 2016 - 2021',
    link: 'http://www.researchandmarkets.com/research/2fdm5m/the_internet_of',
    publication: 'Research and Markets',
    pubmonth: 'June',
    pubyear: 2016,
    rank: 2,
  },
  {
    title: 'Medical World Americas - Day 2 Recap',
    link: 'http://www.tmcnews.org/2016/05/dean-kamen-spotlights-inventions-robot-building-kids-at-conference/',
    publication: 'Texas Medical Center News',
    pubmonth: 'May',
    pubyear: 2016,
    rank: 0,
  },
  {
    title: 'The Internet of Things and Staying Healthy',
    link: 'https://blog.equinix.com/blog/2016/05/02/the-internet-of-things-and-staying-healthy/',
    publication: 'Equinix',
    pubmonth: 'May',
    pubyear: 2016,
    rank: 2,
  },
  {
    title: 'Disrupt 100: AdhereTech',
    link: 'http://2016.disrupt100.com/company/adheretech/',
    publication: 'Tallt Ventures',
    pubmonth: 'April',
    pubyear: 2016,
    rank: 0,
  },
  {
    title: 'Crossing the Technology/Life Sciences Divide',
    link: 'http://nvca.org/event/bay-area-life-sciences-roundtable-crossing-technologylife-sciences-divide/',
    publication: 'National Venture Capital Association',
    pubmonth: 'April',
    pubyear: 2016,
    rank: 2,
  },
  {
    title: 'Telling Technology: Increasing Adherence, Tagging Triggers',
    link: 'http://www.pharmacytimes.com/news/telling-technology-increasing-adherence-tagging-triggers',
    publication: 'Pharmacy Times',
    pubmonth: 'March',
    pubyear: 2016,
    rank: 0,
  },
  {
    title: '10 Services That Will Make Your Brand Stand Out as a Leader',
    link: 'https://www.pm360online.com/10-services-that-will-make-your-brand-stand-out-as-a-leader/',
    publication: 'PM360',
    pubmonth: 'March',
    pubyear: 2016,
    rank: 1,
  },
  {
    title: 'Overcoming the Challenges of the Internet of Things (IoT)',
    link: 'http://electronicsmaker.com/the-challenges-of-the-internet-of-things-iot',
    publication: 'Electronics Maker',
    pubmonth: 'March',
    pubyear: 2016,
    rank: 2,
  },
  {
    title: 'The Future of Digital Health',
    link: 'https://www.cbinsights.com/research-digital-health-trends',
    publication: 'CB Insights',
    pubmonth: 'March',
    pubyear: 2016,
    rank: 3,
  },
  {
    title: 'Digitizing the Safety Net: Health-Tech Opportunities for the Underserved',
    link: 'https://www.chcf.org/wp-content/uploads/2017/12/PDF-DigitizingSafetyNet.pdf',
    publication: 'California Health Care Foundation',
    pubmonth: 'February',
    pubyear: 2016,
    rank: 0,
  },
  {
    title: "NYCEDC Announces New And Expanded Initiatives to Grow City's Health Technology Sector",
    link: 'http://www.nycedc.com/press-release/nycedc-announces-new-and-expanded-initiatives-grow-citys-health-technology-sector',
    publication: 'NYCEDC',
    pubmonth: 'February',
    pubyear: 2016,
    rank: 1,
  },
  {
    title: '12 NYC IoT Companies Connecting Everything to Everything Else',
    link: 'https://www.builtinnyc.com/2017/01/01/iot-companies-making-awesome-products-nyc',
    publication: 'Built in NYC',
    pubmonth: 'February',
    pubyear: 2016,
    rank: 2,
  },
  {
    title: 'New "Smart Pill Bottle" Knows When You\'ve Taken Your Medication',
    link: 'http://abc7ny.com/technology/new-smart-pill-bottle-knows-when-youve-taken-your-medication/1188691/',
    publication: 'ABC News',
    pubmonth: 'February',
    pubyear: 2016,
    rank: 3,
  },
  {
    title: 'AdhereTech Wins 2016 GOOD DESIGN Award',
    link: 'https://chi-athenaeum.org/medical-2016/2016/11/28/smart-pill-bottle-2016/',
    publication: 'The Chicago Athenaeum Museum of Architecture and Design',
    pubmonth: 'February',
    pubyear: 2016,
    rank: 4,
  },
  {
    title: 'Meet the Companies Disruption your Prescriptions',
    link: 'https://www.freeenterprise.com/meet-the-companies-disrupting-healthcare/',
    publication: 'Free Enterprise, via the U.S. Chamber of Commerce',
    pubmonth: 'January',
    pubyear: 2016,
    rank: 0,
  },
  {
    title: 'How User-Centered Design Helps Patients Stay Healthy',
    link: 'https://centricdigital.com/blog/user-experience/user-centered-design-helps-patients-stay-healthy/',
    publication: 'Centric Digital',
    pubmonth: 'January',
    pubyear: 2016,
    rank: 1,
  },
  {
    title: '30 Disruptive Healthcare Companies to Watch',
    link: 'http://www.beckershospitalreview.com/hospital-management-administration/30-disruptive-healthcare-companies-to-watch.html',
    publication: "Becker's Hospital Review",
    pubmonth: 'January',
    pubyear: 2016,
    rank: 2,
  },
  {
    title:
      'Intelligent Product Solutions (IPS) Partners With AdhereTech to Develop the First Internet of Things (IOT) Pill Bottle',
    link: 'http://finance.yahoo.com/news/intelligent-product-solutions-ips-partners-153000588.html',
    publication: 'Yahoo',
    pubmonth: 'January',
    pubyear: 2016,
    rank: 4,
  },
  {
    title: '"Smart" pill bottle knows if you miss a dose',
    link: 'http://www.newsday.com/business/smart-pill-bottle-knows-if-you-missed-a-dose-1.11291326',
    publication: 'Newsday',
    pubmonth: 'January',
    pubyear: 2016,
    rank: 6,
  },
  {
    title: 'Smart Pill Bottle Reminds Patients to Take Their Medication',
    link: 'https://www.designnews.com/medical/smart-pill-bottle-reminds-patients-take-their-medication/189300755146256',
    publication: 'Design News',
    pubmonth: 'January',
    pubyear: 2016,
    rank: 7,
  },
  {
    title:
      'Global Internet of Things (IoT) Healthcare Market Size, Share, Development, Growth and Demand Forecast to 2020',
    link: 'http://www.researchandmarkets.com/research/v6tvnz/global_internet',
    publication: 'Research and Markets',
    pubmonth: 'January',
    pubyear: 2016,
    rank: 8,
  },
  {
    title: 'Drugs go wireless',
    link: 'http://www.nature.com/articles/nbt.3446.epdf?shared_access_token=YQX_GcTjJlw4WopM9RNXM9RgN0jAjWel9jnR3ZoTv0PqOtSSoEHl8sJIXBKHQPsLrYdSsq-e_HzDzCNVYF745knY4QmBFT9kPrz4YFSfH3iFW5wZo-IlJl3JMYn4wuOZx5ZiQAqvD7PIlzEGNUdoaapZKDEUSEaATIdh9fq55hI%3D',
    publication: 'Nature.com',
    pubmonth: 'January',
    pubyear: 2016,
    rank: 9,
  },
  {
    title: 'Global Digital Health 100',
    link: 'http://media.wix.com/ugd/85fd63_6ae8cc321dc64b018ae09e07d8ad0ace.pdf',
    publication: 'The Journal of mHealth',
    pubmonth: 'December',
    pubyear: 2015,
    rank: 0,
  },
  {
    title: 'Acting on the Affordable Care Act',
    link: 'http://whartonmagazine.com/issues/fall-2015/acting-on-the-affordable-care-act/',
    publication: 'Wharton Magazine',
    pubmonth: 'November',
    pubyear: 2015,
    rank: 1,
  },
  {
    title: 'Smart Pill Bottle Provides Easy Way to Monitor Daily Medicine Intake',
    link: 'https://www.mdtmag.com/article/2015/11/smart-pill-bottle-provides-easy-way-monitor-daily-medicine-intake',
    publication: 'Medical Design Technology',
    pubmonth: 'November',
    pubyear: 2015,
    rank: 4,
  },
  {
    title: 'Internet of Things discussion at IMPACT investor conference',
    link: 'http://medcitynews.com/2015/11/stephanie-baum-to-moderate-internet-of-things-discussion-at-impact-investor-conference/?rf=1',
    publication: 'MedCity News',
    pubmonth: 'November',
    pubyear: 2015,
    rank: 5,
  },
  {
    title: 'The Entrepreneurs Who Are Retooling Healthcare',
    link: 'http://thepenngazette.com/the-entrepreneurs-who-are-retooling-healthcare/',
    publication: 'The Pennsylvania Gazette',
    pubmonth: 'October',
    pubyear: 2015,
    rank: -5,
  },
  {
    title: "Check out the new generation of AdhereTech's smart pill bottle",
    link: 'http://medcitynews.com/2015/10/medication-adherence/?rf=1',
    publication: 'MedCity News',
    pubmonth: 'October',
    pubyear: 2015,
    rank: -3,
  },
  {
    title: 'AdhereTech launches new bottle',
    link: 'http://mobihealthnews.com/48060/adheretech-launches-new-bottle-sees-20-percent-adherence-boost/',
    publication: 'MobiHealthNews',
    pubmonth: 'October',
    pubyear: 2015,
    rank: -2,
  },
  {
    title: 'A Conversation with the Super-Utilizer Health Innovation Challenge Winners',
    link: 'http://www.chcs.org/resource/a-conversation-with-the-super-utilizer-health-innovation-challenge-winners/',
    publication: 'Center for Health Care Strategies',
    pubmonth: 'October',
    pubyear: 2015,
    rank: -1,
  },
  {
    title: 'Smart Pill Bottle Averts Medical Mishaps',
    link: 'http://www.ft.com/intl/cms/s/2/03056060-5bd1-11e5-9846-de406ccb37f2.html#axzz3p6oNvr3G',
    publication: 'Financial Times',
    pubmonth: 'October',
    pubyear: 2015,
    rank: 0,
  },
  {
    title: 'Why Thingify: AdhereTech',
    link: 'https://www.youtube.com/watch?v=IZMQkgmAoOE',
    publication: 'Intelligent Product Solutions',
    pubmonth: 'October',
    pubyear: 2015,
    rank: 1,
  },
  {
    title: 'Relationship-Centric Technology: Improving the Human Experience for Better Engagement',
    link: 'http://www.pharmexec.com/relationship-centric-technology-improving-human-experience-better-engagement',
    publication: 'Pharmaceutical Executive',
    pubmonth: 'September',
    pubyear: 2015,
    rank: 0,
  },
  {
    title: '25 Disruptive Healthcare Companies to Watch',
    link: 'http://www.beckershospitalreview.com/hospital-management-administration/25-disruptive-healthcare-companies-to-watch.html',
    publication: "Becker's Hospital Review",
    pubmonth: 'September',
    pubyear: 2015,
    rank: 1,
  },
  {
    title: "In New York, A Digital Health 'Mashup' Builds A Startup Army",
    link: 'http://www.xconomy.com/new-york/2015/08/24/in-new-york-a-digital-health-mashup-builds-a-startup-army/',
    publication: 'Xconomy',
    pubmonth: 'August',
    pubyear: 2015,
    rank: 1,
  },
  {
    title: 'Which Company Will Change the Future of Healthcare? Here are 10 Contenders',
    link: 'http://www.huffingtonpost.com/dr-david-samadi/which-company-will-change_b_7971132.html',
    publication: 'The Huffington Post',
    pubmonth: 'August',
    pubyear: 2015,
    rank: 2,
  },
  {
    title: 'Guide to the Latest in Domestic Smart-Tech',
    link: 'http://idealog.co.nz/tech/2015/08/realm-sensors-idealog-guide-latest-domestic-smart-tech',
    publication: 'Idealog',
    pubmonth: 'August',
    pubyear: 2015,
    rank: 3,
  },
  {
    title: 'Try These 3 Apps To Help Organize Your Medication',
    link: 'http://www.sunriseseniorliving.com/blog/july-2015/try-these-3-apps-to-help-organize-your-medication.aspx',
    publication: 'Sunrise Senior Living',
    pubmonth: 'July',
    pubyear: 2015,
    rank: 1,
  },
  {
    title: "AdhereTech's Smart Pill Bottle Gets a New Design",
    link: 'http://telecareaware.com/adheretechs-smart-pill-bottle-gets-a-redesign/',
    publication: 'Telehealth & Telecare Aware',
    pubmonth: 'July',
    pubyear: 2015,
    rank: 3,
  },
  {
    title: 'Active Packaging Gets Smarter',
    link: 'http://read.nxtbook.com/wiley/plasticsengineering/june2015/activepackaginggetssmarter.html',
    publication: 'Plastics Engineering',
    pubmonth: 'June',
    pubyear: 2015,
    rank: 0,
  },
  {
    title: 'Smart Drugs: Where IoT Meets Healthcare, a Market Snapshot',
    link: 'http://siliconangle.com/blog/2015/06/30/smart-drugs-where-iot-meets-healthcare-a-market-snapshot/',
    publication: 'SiliconANGLE',
    pubmonth: 'June',
    pubyear: 2015,
    rank: 1,
  },
  {
    title: "PACT's Enterprise App Challenge Awards $80K to 4 Winners",
    link: 'http://technical.ly/philly/2015/06/30/pact-enterprise-app-challenge-winners/',
    publication: 'Technical.ly Philly',
    pubmonth: 'June',
    pubyear: 2015,
    rank: 2,
  },
  {
    title: 'StartUp Health Gets Strategic Investment from Wisconsin Health System',
    link: 'http://mobihealthnews.com/44610/startup-health-gets-strategic-investment-from-wisconsin-health-system/',
    publication: 'MobiHealthNews',
    pubmonth: 'June',
    pubyear: 2015,
    rank: 4,
  },
  {
    title: "Aurora's Business Enterprise Unit Invests in Digital Health Accelerator StartUp Health",
    link: 'http://www.bizjournals.com/milwaukee/news/2015/06/23/auroras-business-enterprise-unit-invests-in.html',
    publication: 'The Busines Journals',
    pubmonth: 'June',
    pubyear: 2015,
    rank: 5,
  },
  {
    title: 'High-Tech Monitors, Cool Gadgets Help Spark a Health Care Revolution',
    link: 'http://www.usatoday.com/story/news/nation/2015/06/11/healthcare-technology/28557075/',
    publication: 'USA Today',
    pubmonth: 'June',
    pubyear: 2015,
    rank: 6,
  },
  {
    title: 'IoT Enables New Non-Invasive Patient Monitoring',
    link: 'https://www.ptc.com/en/product-lifecycle-report/iot-enables-new-non-invasive-patient-monitoring',
    publication: 'PTC.com',
    pubmonth: 'June',
    pubyear: 2015,
    rank: 7,
  },
  {
    title: 'Cool Gadgets Help Spark Healthcare Revolution',
    link: 'http://ncet.org/cool-gadgets-help-spark-health-care-revolution/',
    publication: 'NCET',
    pubmonth: 'June',
    pubyear: 2015,
    rank: 8,
  },
  {
    title: 'Will Wearables Change the Future of Healthcare?',
    link: 'http://www.pm360online.com/will-wearables-change-the-future-of-healthcare/',
    publication: 'PM360',
    pubmonth: 'June',
    pubyear: 2015,
    rank: 9,
  },
  {
    title: 'The Internet of Things Map',
    link: 'https://cbi-blog.s3.amazonaws.com/blog/wp-content/uploads/2015/05/IoT-Map.png',
    publication: 'CB Insights',
    pubmonth: 'June',
    pubyear: 2015,
    rank: 11,
  },
  {
    title: 'How Beeps and Better Bones Are Leading to Smarter Healthcare',
    link: 'http://adheretech.tumblr.com/post/119363241455/ibms-smarter-planet-campaign-praises-adheretechs',
    publication: 'IBM',
    pubmonth: 'May',
    pubyear: 2015,
    rank: 0,
  },
  {
    title: '10 Startups That Are Disrupting Healthcare IT',
    link: 'http://www.cio.com/article/2926436/healthcare/10-startups-that-are-disrupting-healthcare-it.html#slide6',
    publication: 'CIO.com',
    pubmonth: 'May',
    pubyear: 2015,
    rank: 1,
  },
  {
    title: 'A Bet On The Not-So-Future Internet Of Things',
    link: 'http://www.innovateli.com/a-bet-on-the-not-so-future-internet-of-things/',
    publication: 'Innovate Long Island',
    pubmonth: 'May',
    pubyear: 2015,
    rank: 2,
  },
  {
    title: 'Smart Bottle Saves Lives and Money',
    link: 'http://healthtechinsider.com/2015/05/11/smart-bottle-saves-lives-and-money/',
    publication: 'Health Tech Insider',
    pubmonth: 'May',
    pubyear: 2015,
    rank: 3,
  },
  {
    title: '30 HealthTech Startups with Real Potential to Change the World',
    link: 'http://www.alleywatch.com/2015/05/30-healthtech-startups-with-the-potential-to-change-the-world/7/',
    publication: 'Alley Watch',
    pubmonth: 'May',
    pubyear: 2015,
    rank: 4,
  },
  {
    title: 'National Institute of Health: Precision Medicine Initiative',
    link: 'http://www.nih.gov/precisionmedicine/speaker-bios-20150428.htm',
    publication: 'The National Institute of Health',
    pubmonth: 'April',
    pubyear: 2015,
    rank: 0,
  },
  {
    title: 'Unique Considerations When Designing For New Hardware',
    link: 'https://medium.com/@lsdotson/designers-say-unique-considerations-when-designing-for-new-hardware-d1272b471341',
    publication: 'Medium',
    pubmonth: 'April',
    pubyear: 2015,
    rank: 1,
  },
  {
    title: 'Big Data - The Next Big Thing',
    link: 'http://www.pmlive.com/pharma_thought_leadership/big_data_the_next_big_thing_715878',
    publication: 'PMLive',
    pubmonth: 'April',
    pubyear: 2015,
    rank: 2,
  },
  {
    title: '10 Trends Transforming Healthcare in 2015',
    link: 'http://www.mmm-online.com/agency/10-trends-transforming-healthcare-in-2015/article/405917/',
    publication: 'MM&M Magazine',
    pubmonth: 'April',
    pubyear: 2015,
    rank: 3,
  },
  {
    title: 'TEDMED Talk by AdhereTech CEO Josh Stein',
    link: 'http://tedmed.com/talks/show?id=299427',
    publication: 'TEDMED',
    pubmonth: 'March',
    pubyear: 2015,
    rank: 0,
  },
  {
    title: "AdhereTech on 'Innovation Navigation' Podcast & Sirius XM's Business Radio",
    link: 'https://itunes.apple.com/us/podcast/innovation-navigation/id920056875?mt=2',
    publication: 'Innovation Navigation Podcast',
    pubmonth: 'March',
    pubyear: 2015,
    rank: 0,
  },
  {
    title: "The Emergence of the 'Internet of Medical Things'",
    link: 'http://www.huffingtonpost.com/josh-stein/the-emergence-of-the-inte_b_6801714.html',
    publication: 'The Huffington Post',
    pubmonth: 'March',
    pubyear: 2015,
    rank: 1,
  },
  {
    title: "It's Smart to Design Simple",
    link: 'http://blog.tedmed.com/its-smart-to-design-simple/',
    publication: 'TEDMED Blog',
    pubmonth: 'March',
    pubyear: 2015,
    rank: 2,
  },
  {
    title: '12 Companies Leading the Way in Digital Health',
    link: 'http://www.techrepublic.com/article/12-companies-leading-the-way-in-digital-health/',
    publication: 'TechRepublic',
    pubmonth: 'March',
    pubyear: 2015,
    rank: 3,
  },
  {
    title: "Diving Deep into 'The Internet of Things'",
    link: 'http://www.msnbc.com/msnbc/watch/diving-deep-into-the-internet-of-things-398182979760',
    publication: 'MSNBC',
    pubmonth: 'February',
    pubyear: 2015,
    rank: 0,
  },
  {
    title: 'Interesting Bedfellows That Are Seeking to Change Healthcare or Very Well Could',
    link: 'http://medcitynews.com/2015/02/interesting-bedfellows-changing-healthcare/',
    publication: 'MedCity News',
    pubmonth: 'February',
    pubyear: 2015,
    rank: 1,
  },
  {
    title: 'What "The Internet of Things" Really Is - and Why It Matters',
    link: 'http://intelligentproduct.solutions/blog/nbc-smart-money-iot/',
    publication: "NBC's Smart Money",
    pubmonth: 'February',
    pubyear: 2015,
    rank: 2,
  },
  {
    title: 'What 228% Funding Growth Means for Health Tech',
    link: 'http://www.crainsnewyork.com/article/20150203/HEALTH_CARE/302019999/funding-pours-in-for-health-tech-startups',
    publication: "Crain's New York Business",
    pubmonth: 'February',
    pubyear: 2015,
    rank: 3,
  },
  {
    title: 'Bold: How to Go Big, Create Wealth and Impact the World',
    link: 'https://books.google.com/books?id=qy-VCwAAQBAJ&pg=PA301&lpg=PA301&dq=bold+adheretech&source=bl&ots=WlBE-Uo7Vt&sig=thhEReeI8Vc87xCZ0T7rvtSpVzo&hl=en&sa=X&ved=0ahUKEwiwp9WVuN_NAhVBVj4KHfowBcQQ6AEIITAB#v=onepage&q=adheretech&f=false',
    publication: "Peter H. Diamandis & Steven Kotler's Best-Selling Book",
    pubmonth: 'February',
    pubyear: 2015,
    rank: 4,
  },
  {
    title: 'How Tech is Helping Seniors Live at Home, Not in a Home',
    link: 'http://venturebeat.com/2015/01/21/how-technology-can-help-seniors-live-at-home-not-in-a-home/#',
    publication: 'Humana, via VentureBeat',
    pubmonth: 'January',
    pubyear: 2015,
    rank: 0,
  },
  {
    title: 'The Top 40 Healthcare Transformers',
    link: 'http://www.mmm-online.com/the-top-40-healthcare-transformers/article/389697/',
    publication: 'MM&M Magazine',
    pubmonth: 'January',
    pubyear: 2015,
    rank: 1,
  },
  {
    title: '9 Healthcare Tech Startups To Watch',
    link: 'http://www.informationweek.com/healthcare/leadership/9-healthcare-tech-startups-to-watch/d/d-id/1318263?_mc=RSS_IWK_EDT&image_number=6',
    publication: 'InformationWeek',
    pubmonth: 'December',
    pubyear: 2014,
    rank: 1,
  },
  {
    title: 'Top 10 mHealth News Stories of 2014',
    link: 'http://www.mobihealthnews.com/news/top-10-mhealth-news-stories-2014/page/0/1',
    publication: 'mHealth News',
    pubmonth: 'December',
    pubyear: 2014,
    rank: 2,
  },
  {
    title: 'Medication Management Video',
    link: 'http://www.longevitynetwork.org/explore/medication-management/',
    publication: 'The Longevity Network, via UnitedHealthcare and AARP',
    pubmonth: 'December',
    pubyear: 2014,
    rank: 2,
  },
  {
    title: '5 Approaches Companies Are Taking to Build Better Pill Takers',
    link: 'http://medcitynews.com/2014/12/10-approaches-companies-taking-create-better-pill-takers/',
    publication: 'MedCity News',
    pubmonth: 'December',
    pubyear: 2014,
    rank: 4,
  },
  {
    title: 'Powerful Answers Finalists Are Innovating Home Life as We Know It',
    link: 'http://www.verizon.com/about/news/powerful-answers-finalists-are-innovating-home-life-we-know-it/',
    publication: 'Verizon',
    pubmonth: 'November',
    pubyear: 2014,
    rank: 0,
  },
  {
    title: 'Smart Pill Bottle Prevents Missed Doses',
    link: 'http://www.pharmacytimes.com/contributor/beth-bolt-rph/2014/11/smart-pill-bottle-prevents-missed-doses',
    publication: 'Pharmacy Times',
    pubmonth: 'November',
    pubyear: 2014,
    rank: 1,
  },
  {
    title: 'CEWIT 2014: AdhereTech and the Next Gen Smart Pill Bottle',
    link: 'https://www.youtube.com/watch?v=Q1Xrrc9nbrE',
    publication: 'Intelligent Product Solutions via YouTube',
    pubmonth: 'November',
    pubyear: 2014,
    rank: 2,
  },
  {
    title: 'Patient Non-Adherence: Perceived Challenge or Next Big Opportunity',
    link: 'http://businesstoday.intoday.in/story/pharmaceutical-companies-digital-health-start-up-drug-companies/1/211981.html',
    publication: 'Business Today',
    pubmonth: 'November',
    pubyear: 2014,
    rank: 3,
  },
  {
    title: '21 People in IoT in NYC You Need To Know About',
    link: 'http://www.alleywatch.com/2014/10/21-people-in-iot-in-nyc-you-need-to-know-about/16/',
    publication: 'AlleyWatch',
    pubmonth: 'October',
    pubyear: 2014,
    rank: -1,
  },
  {
    title: "Gadget Gurus Proliferate in NY's Tech Scene",
    link: 'http://www.crainsnewyork.com/article/20141021/SMALLBIZ/310199994',
    publication: "Crain's New York",
    pubmonth: 'October',
    pubyear: 2014,
    rank: 0,
  },
  {
    title: 'The Devices Making Healthcare Smarter',
    link: 'http://newsroom.cisco.com/feature-content?type=webcontent&articleId=1500391',
    publication: 'Cisco',
    pubmonth: 'October',
    pubyear: 2014,
    rank: 1,
  },
  {
    title: "LIVESTRONG 'Big C' Competition: AdhereTech Video",
    link: 'https://www.youtube.com/watch?v=RSKbQg9KZLM',
    publication: 'LIVESTRONG via YouTube',
    pubmonth: 'October',
    pubyear: 2014,
    rank: 3,
  },
  {
    title: 'New York Digital Health Startups Thriving',
    link: 'http://www.crainsnewyork.com/article/20141002/HEALTH_CARE/141009961/new-york-digital-health-startups-thriving',
    publication: "Crain's New York Business",
    pubmonth: 'October',
    pubyear: 2014,
    rank: 4,
  },
  {
    title: 'StartUp Health NOW #1: AdhereTech',
    link: 'http://www.startuphealth.com/videoPage/bLeSoLTnWg8?sf32545564=1',
    publication: 'StartUp Health NOW',
    pubmonth: 'October',
    pubyear: 2014,
    rank: 5,
  },
  {
    title: 'Cancer Foundation Names Finalists For Innovation In Patient-Centered Care',
    link: 'http://www.forbes.com/sites/robertszczerba/2014/09/29/cancer-foundation-names-finalists-for-innovation-in-patient-centered-care/#6941a4b6605b',
    publication: 'Forbes',
    pubmonth: 'September',
    pubyear: 2014,
    rank: 0,
  },
  {
    title: 'How Ingestible Sensors and Smart Pills Will Revolutionize Healthcare',
    link: 'http://www.marsdd.com/news-and-insights/ingestibles-smart-pills-revolutionize-healthcare/',
    publication: 'MaRS',
    pubmonth: 'September',
    pubyear: 2014,
    rank: 1,
  },
  {
    title: 'Consumer engagement: Is less more?',
    link: 'http://blogs.deloitte.com/centerforhealthsolutions/consumer-engagement-is-less-more/',
    publication: 'Deloitte',
    pubmonth: 'September',
    pubyear: 2014,
    rank: 3,
  },
  {
    title: 'TEDMED DC Day 3: First Imagine, Then Amplify',
    link: 'http://www.medgadget.com/2014/09/tedmed-dc-day-3-first-imagine-then-amplify.html',
    publication: 'medGadget',
    pubmonth: 'September',
    pubyear: 2014,
    rank: 4,
  },
  {
    title: '10 Hot Internet of Things Startups',
    link: 'http://www.cio.com/article/2602467/consumer-technology/10-hot-internet-of-things-startups.html',
    publication: 'CIO.com',
    pubmonth: 'September',
    pubyear: 2014,
    rank: 6,
  },
  {
    title: 'Medication Adherence Apps Coming of Age',
    link: 'http://www.pharmacypracticenews.com/Technology/Article/09-14/Medication-Adherence-Apps-Coming-of-Age/28199/ses=ogst',
    publication: 'Pharmacy Practice News',
    pubmonth: 'September',
    pubyear: 2014,
    rank: 7,
  },
  {
    title: 'TEDMED Speakers',
    link: 'http://blog.tedmed.com/?p=5680',
    publication: 'TEDMED',
    pubmonth: 'August',
    pubyear: 2014,
    rank: 0,
  },
  {
    title: 'Smarter Pills: Improving Medication Adherence with Technology',
    link: 'http://www.empr.com/smarter-pills-improving-medication-adherence-with-technology/article/368075/',
    publication: 'Monthly Prescribing Reference',
    pubmonth: 'August',
    pubyear: 2014,
    rank: 1,
  },
  {
    title: 'How Developer Challenges Make the Difference',
    link: 'http://thehealthcareblog.com/blog/2014/08/15/giving-startups-their-big-start-how-developer-challenges-make-the-difference/',
    publication: 'Health 2.0',
    pubmonth: 'August',
    pubyear: 2014,
    rank: 2,
  },
  {
    title: 'AdhereTech Gets $1.75M to Redesign Its Smart Pill Bottle',
    link: 'http://mobihealthnews.com/33429/adheretech-gets-1-75m-to-redesign-its-smart-pill-bottle/',
    publication: 'MobiHealthNews',
    pubmonth: 'July',
    pubyear: 2014,
    rank: 2,
  },
  {
    title: 'AdhereTech, the Developer Behind Smart Pill Bottle Technology, Raises $1.75M',
    link: 'http://medcitynews.com/2014/07/adheretech-developers-behind-smart-pill-bottle-raises-1-75m/',
    publication: 'MedCity News',
    pubmonth: 'July',
    pubyear: 2014,
    rank: 3,
  },
  {
    title: 'Now in Use, Smart Pill Bottle Gets Second Patent',
    link: 'http://www.uah.edu/news/research/7951-now-in-use-uah-invented-smart-pill-bottle-gets-second-patent#.U7q6QI1dVVd',
    publication: 'The University of Alabama in Huntsville',
    pubmonth: 'June',
    pubyear: 2014,
    rank: 0,
  },
  {
    title: 'Pascal Cagni: AdhereTech is Disrupting Med Adherence',
    link: 'http://adheretech.tumblr.com/post/89754488555/pascal-cagni-praises-adheretech-as-leading',
    publication: 'Connected Conference via AdhereTech',
    pubmonth: 'June',
    pubyear: 2014,
    rank: 1,
  },
  {
    title: '3 Sensor Startups Collecting Population Health Data',
    link: 'http://www.mobihealthnews.com/news/3-sensor-startups-collecting-population-health-data',
    publication: 'mHealth News',
    pubmonth: 'June',
    pubyear: 2014,
    rank: 2,
  },
  {
    title: 'Tech Startups Compete to Make You Take Your Medicine',
    link: 'http://blogs.wsj.com/venturecapital/2014/05/21/the-daily-startup-tech-startups-compete-to-make-you-take-your-medicine/',
    publication: 'The Wall Street Journal',
    pubmonth: 'May',
    pubyear: 2014,
    rank: 0,
  },
  {
    title: 'Seven Tips for Healthcare Startups Doing Pilots',
    link: 'http://mobihealthnews.com/33192/seven-tips-for-healthcare-startups-doing-pilots/',
    publication: 'MobiHealthNews',
    pubmonth: 'May',
    pubyear: 2014,
    rank: 1,
  },
  {
    title: '6 Gadgets That Could Save Your Life',
    link: 'http://money.cnn.com/gallery/technology/innovation/2014/05/07/health-gadgets/index.html?iid=HP_LN',
    publication: 'CNN Money',
    pubmonth: 'May',
    pubyear: 2014,
    rank: 2,
  },
  {
    title: "Pharma's Digital Health Opportunities",
    link: 'http://mobihealthnews.com/32728/in-depth-pharmas-digital-health-opportunities/',
    publication: 'MobiHealthNews',
    pubmonth: 'May',
    pubyear: 2014,
    rank: 3,
  },
  {
    title: 'Smart Pill Bottle Could Help You Take Your Meds on Time',
    link: 'http://www.cbsnews.com/news/smart-pill-bottle-could-help-you-take-your-meds-on-time/',
    publication: 'CBS News',
    pubmonth: 'April',
    pubyear: 2014,
    rank: 1,
  },
  {
    title: '6 Hot Healthcare Companies: From Smart Pill Bottles to Smart Diapers',
    link: 'http://www.entrepreneur.com/article/232698',
    publication: 'Entrepreneur',
    pubmonth: 'April',
    pubyear: 2014,
    rank: 2,
  },
  {
    title: '8 Technologies That Are Transforming Health',
    link: 'http://www.businessinsider.com/technologies-that-are-transforming-health-2014-3',
    publication: 'Business Insider',
    pubmonth: 'March',
    pubyear: 2014,
    rank: 0,
  },
  {
    title: '8 Health Technologies to Watch For',
    link: 'http://health.usnews.com/health-news/health-wellness/slideshows/8-health-technologies-to-watch-for/2',
    publication: 'U.S. News & World Report',
    pubmonth: 'March',
    pubyear: 2014,
    rank: 1,
  },
  {
    title: 'Tech Confab Boosts Health-Care Focus',
    link: 'https://www.wsj.com/articles/south-by-southwest-boosts-health-care-focus-1394415021',
    publication: 'The Wall Street Journal',
    pubmonth: 'March',
    pubyear: 2014,
    rank: 2,
  },
  {
    title: "The World's Top 10 Most Innovative Companies in Healthcare",
    link: 'http://www.fastcompany.com/3026367/most-innovative-companies-2014/the-worlds-top-10-most-innovative-companies-in-healthcare',
    publication: 'Fast Company',
    pubmonth: 'February',
    pubyear: 2014,
    rank: 0,
  },
  {
    title: 'Wharton MBA Grad Founds AdhereTech, Building the Pill Bottle of the Future',
    link: 'https://d3n8a8pro7vhmx.cloudfront.net/whartonhealthcare/pages/211/attachments/original/1470928318/WHQ_January_2014.pdf?1470928318',
    publication: 'Wharton Health Care',
    pubmonth: 'February',
    pubyear: 2014,
    rank: 1,
  },
  {
    title: 'AdhereTech Featured in Pilot Health Tech NYC Video',
    link: 'http://blog.adheretech.com/post/74849562836/as-you-know-last-year-adheretech-won-100-000-to',
    publication: 'NYCEDC via AdhereTech',
    pubmonth: 'January',
    pubyear: 2014,
    rank: 0,
  },
  {
    title: "AdhereTech's Smart Pill Bottle Knows When You Take, and Miss, Your Medication",
    link: 'http://www.theverge.com/2014/1/8/5289022/adheretech-smart-pill-bottle',
    publication: 'The Verge',
    pubmonth: 'January',
    pubyear: 2014,
    rank: 3,
  },
  {
    title: 'A New Era in Medication Adherence',
    link: 'http://www.pharmamanufacturing.com/articles/2013/dec-digital-insights/',
    publication: 'PharmaManufacturing.com',
    pubmonth: 'December',
    pubyear: 2013,
    rank: 0,
  },
  {
    title: 'StartUp Health and Google Are Teaming Up to Reduce Pharma Clinical Trial Costs',
    link: 'http://medcitynews.com/2013/11/can-startup-health-google-collaboration-reduce-big-pharma-clinical-trial-costs/',
    publication: 'MedCity News',
    pubmonth: 'November',
    pubyear: 2013,
    rank: 0,
  },
  {
    title: 'Medication Adherence: Whose Problem Is It?',
    link: 'http://mobihealthnews.com/27258/medication-adherence-whose-problem-is-it/',
    publication: 'MobiHealthNews',
    pubmonth: 'November',
    pubyear: 2013,
    rank: 1,
  },
  {
    title: 'AdhereTech Presentation - Stanford Medicine X 2013',
    link: 'https://www.youtube.com/watch?v=ULAZdWS_5Qs',
    publication: 'Stanford Medicine X via YouTube',
    pubmonth: 'November',
    pubyear: 2013,
    rank: 2,
  },
  {
    title: 'High-Tech Health Advances',
    link: 'http://newyork.cbslocal.com/2013/11/01/seen-at-11-high-tech-health-advances/',
    publication: 'CBS News',
    pubmonth: 'November',
    pubyear: 2013,
    rank: 3,
  },
  {
    title: 'AdhereTech Wins Award at Cleveland Clinic Medical Innovation Summit',
    link: 'http://www.prweb.com/releases/2013/10/prweb11264236.htm',
    publication: 'StartUp Health via PRWeb',
    pubmonth: 'October',
    pubyear: 2013,
    rank: 0,
  },
  {
    title: 'People to Know 2013: Josh Stein',
    link: 'http://www.diabetesforecast.org/2013/oct/people-to-know-2013-josh-stein.html',
    publication: "American Diabetes Association's Diabetes Forecast",
    pubmonth: 'October',
    pubyear: 2013,
    rank: 1,
  },
  {
    title: "StartUp Health's New Class Get Pass into Special 3-Year Program",
    link: 'http://venturebeat.com/2013/10/14/startup-health-selects-14-startups-for-its-3-year-training-program/',
    publication: 'VentureBeat',
    pubmonth: 'October',
    pubyear: 2013,
    rank: 2,
  },
  {
    title: 'Technology Puts You in Charge of Your Health',
    link: 'http://www.aarp.org/health/healthy-living/info-09-2013/health-gadgets.html',
    publication: 'AARP',
    pubmonth: 'September',
    pubyear: 2013,
    rank: 0,
  },
  {
    title: '20 Mind-Blowing Medical Breakthroughs',
    link: 'http://adheretech.tumblr.com/post/59024625381/adheretech-listed-as-one-of-readers-digests-20',
    publication: "Reader's Digest via AdhereTech",
    pubmonth: 'September',
    pubyear: 2013,
    rank: 2,
  },
  {
    title: 'App, Smart Pill Bottle Could Help Reduce Readmissions',
    link: 'http://www.healthitoutcomes.com/doc/app-smart-pill-bottle-could-help-reduce-readmissions-0001',
    publication: 'Health IT Outcomes',
    pubmonth: 'August',
    pubyear: 2013,
    rank: 0,
  },
  {
    title: 'AdhereTech selects KORE for M2M Healthcare',
    link: 'https://www.businesswire.com/news/home/20130827005106/en/AdhereTech-Selects-KORE-Innovative-M2M-Healthcare-Application',
    publication: 'M2M Magazine',
    pubmonth: 'August',
    pubyear: 2013,
    rank: 1,
  },
  {
    title: 'The Obamacare Start-Up Boom',
    link: 'http://content.time.com/time/magazine/article/0,9171,2148639,00.html',
    publication: 'TIME',
    pubmonth: 'August',
    pubyear: 2013,
    rank: 2,
  },
  {
    title: 'UAH Scientists Develop "Smart" Pill Bottle',
    link: 'http://whnt.com/2013/08/09/uah-scientists-develop-smart-pill-bottle/',
    publication: 'WHNT News',
    pubmonth: 'August',
    pubyear: 2013,
    rank: 3,
  },
  {
    title: "Today's Hottest HealthTech Startup in NYC - AdhereTech",
    link: 'http://www.alleywatch.com/2013/07/todays-hottest-healthtech-startup-in-nyc-adhere-tech/',
    publication: 'AlleyWatch',
    pubmonth: 'July',
    pubyear: 2013,
    rank: 0,
  },
  {
    title: 'Forget to Take Medicine? These Pills Will Tell Your Doctor',
    link: 'https://www.wsj.com/articles/SB10001424127887324787004578494993650743434',
    publication: 'The Wall Street Journal',
    pubmonth: 'May',
    pubyear: 2013,
    rank: 0,
  },
  {
    title: 'A Cell Phone In A Pill Bottle, to Text You to Remember Your Meds',
    link: 'https://www.fastcompany.com/2681935/a-cell-phone-in-a-pill-bottle-to-text-you-to-remember-your-meds',
    publication: 'Fast Company',
    pubmonth: 'May',
    pubyear: 2013,
    rank: 1,
  },
  {
    title: 'Blueprint Spins Out Health Tech',
    link: 'http://www.crainsnewyork.com/article/20130424/TECHNOLOGY/130429948',
    publication: "Crain's New York Business",
    pubmonth: 'April',
    pubyear: 2013,
    rank: 0,
  },
  {
    title: 'Mobile Health Startups Talk Innovation, Acquisition',
    link: 'http://mobihealthnews.com/21720/mobile-health-startups-talk-innovation-acquisition/',
    publication: 'TEDMED via MobiHealthNews',
    pubmonth: 'April',
    pubyear: 2013,
    rank: 1,
  },
  {
    title: 'Technology for the Real World Emerging in NYC Among Digital Startups',
    link: 'http://www.xconomy.com/new-york/2013/03/28/technology-for-the-real-world-emerging-in-nyc-among-digital-startups/',
    publication: 'Xconomy',
    pubmonth: 'March',
    pubyear: 2013,
    rank: 0,
  },
  {
    title: 'Tech Universe',
    link: 'http://www.nzherald.co.nz/technology/news/article.cfm?c_id=5&objectid=10874160',
    publication: 'The New Zealand Herald',
    pubmonth: 'March',
    pubyear: 2013,
    rank: 1,
  },
  {
    title: 'This Pill Bottle Is a Smartphone Wannabe',
    link: 'http://www.wired.com/2013/03/adhere-tech-smart-pill-bottle/',
    publication: 'Wired',
    pubmonth: 'March',
    pubyear: 2013,
    rank: 2,
  },
  {
    title: 'Start-up Developing Smart Pill Bottle Targets HIV, Cancer, Transplant Meds & Pharmacies',
    link: 'http://medcitynews.com/2013/01/start-up-developing-smart-pill-bottle-targets-hiv-cancer-transplant-drugs-and-speciality-pharmacies/',
    publication: 'MedCity News',
    pubmonth: 'January',
    pubyear: 2013,
    rank: 0,
  },
  {
    title: "Blueprint Health's Latest Class Tackles Drug Adherence, Other Issues",
    link: 'http://www.xconomy.com/new-york/2012/10/09/blueprint-healths-latest-class-tackles-drug-adherence-other-issues/',
    publication: 'Xconomy',
    pubmonth: 'October',
    pubyear: 2012,
    rank: 0,
  },
  {
    title: 'Smart Pill Bottle Measures Meds Using Touchscreen Technology',
    link: 'http://www.theverge.com/2012/10/8/3473218/smart-pill-bottle-adheretech-capacitance',
    publication: 'The Verge',
    pubmonth: 'October',
    pubyear: 2012,
    rank: 1,
  },
]
