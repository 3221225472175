import React from 'react'

import pressItems from '../content/pressItems'
import PressCard from '../components/PressCard'

const PressPage = () => {
  return (
    <main className="pressPage">
      <div className="page-section">
        <h1 className="press-heading">In the news</h1>
        {pressItems.map((pressItem, i) => {
          return <PressCard data={pressItem} key={i} />
        })}
      </div>
    </main>
  )
}

export default PressPage
