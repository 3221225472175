import React from 'react'

import { FaExternalLinkAlt } from 'react-icons/fa'

export default function PressCard({ data }) {
  return (
    <div className="press-card">
      <a className="press-card-content" href={data.link} target="_blank" rel="noreferrer">
        <time className="press-card-pubdate">
          {data.pubmonth}&nbsp;{data.pubyear}
        </time>
        <h3 className="press-card-title">{data.title}</h3>
        <div>
          <span className="press-card-publication">{data.publication}</span>
        </div>
        <FaExternalLinkAlt className="press-card-link" size={18} color="#0690cc" />
      </a>
    </div>
  )
}
